<template>
    <div>
        <b-modal id="modal-1" size="xl" title="Daftar Mahasiswa Belum Konfrmasi Pembayaran" ok-only ok-title="Accept"
            no-close-on-backdrop hide-footer>
            <daftar-mahasiswa :items = items @loadData = loadData :Rowstotal = items.length></daftar-mahasiswa>>
        </b-modal>
    </div>
</template>

<script>
    import {
        BRow,
        BCol,
        BModal,
        BButton,
        VBModal,
        BAlert
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import DaftarMahasiswa from './DaftarMahasiswa.vue'

    export default {
        components: {
            BButton,
            BRow,
            BCol,
            BModal,
            BAlert,
            DaftarMahasiswa,
        },
        props : {
            items : {

            }
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        emits :['load'],
        methods : {  
            loadData(){
                this.$emit('load')
            }
        }
    }
</script>