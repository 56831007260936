<template>
    <div> 
        <b-row style="margin-bottom:-5px;">
            <b-col md="3" sm="4" class="mb-1">
                <b-form-group class="mb-0">
                    <label class="d-inline-block text-sm-left mr-50"></label>
                    <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
                </b-form-group>
            </b-col>
            <b-col md="6" sm="8" class="my-1">
            </b-col>
            <b-col md="3" class=''>
                <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
                    class="mb-0">
                    <b-input-group size="sm">
                        <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-table thead-class="hidden_header" striped hover responsive :per-page="perPage"
                    :current-page="currentPage" :items="items" :fields="fields" :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
                    :filter-included-fields="filterOn" @filtered="onFiltered">
                    <template #cell(tagihan)="data">

                        <b-row class="">
                            <b-col md="4">
                                <b-alert variant=" " show style="margin-left:-15px;">
                                    <div class="alert-body">
                                        <h4><small><strong>{{ data.item.nm_jenis_pembayaran }} (Kelas {{ data.item.kelas }})</strong></small> <br> Periode
                                             {{ data.item.nama }}
                                        </h4>
                                        <b-row>
                                            <b-col md="12">
                                                <small>Periode pembayaran </small><br>
                                                <!-- <b-avatar variant="warning" rounded="" size="25" class="mr-1">
                                                    <feather-icon icon="CalendarIcon" size="15" />
                                                </b-avatar> -->
                                                {{ data.item.tgl_mulai }}-  {{ data.item.tgl_selesai }}
                                                <b-button class="mt-1" block size="sm" variant="outline-warning" v-show="data.item.st_periode != ''">{{ data.item.st_periode }}</b-button>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </b-alert>
                            </b-col>

                            <b-col md="3" class="match-height">
                                <small>Atasnama : <br><strong> {{ data.item.atas_nama }}</strong></small>
                                <h6 class="">Bank {{ data.item.bank }} {{ data.item.no_rekening }}</h6>
                                <small class="text-primary">Nominal Bayar</small>
                                <h4 class="text-primary"><sup>Rp. </sup> <strong>{{ Rp(data.item.nominal) }}</strong></h4>

                            </b-col>
                            <b-col md="5">
                                <div class="row mt-1">
                                    <div class="col-xl-6 col-sm-6 col-12 mb-2 mb-sm-0">
                                        <div class="media">
                                            <div class="media-body my-auto">
                                                <p class="card-text font-small-3  mb-0">Total Pemngajuan
                                                    Pengajuan</p>
                                                <h4 class="font-weight-bolder   mb-0">{{data.item.jm_tagihan}} Mahasiswa</h4>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-sm-6 col-12 mb-2 mb-xl-0">
                                        <div class="media">
                                            <div class="media-body my-auto">
                                                <p class="card-text font-small-3  mb-0">   Belum Konfirmasi</p>
                                                <h4 class="font-weight-bolder   mb-0"> {{ data.item.belum }} Mahasiswa</h4>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <b-row class="mt-1">
                                    <b-col> 
                                        <b-button block size="sm" variant="warning" v-b-modal.modal-1  @click="loadDetail(data.item.id_preiode_pembayaran)">
                                           
                                                <feather-icon icon="CalendarIcon" size="15"  /> Detail Belum Konfirmasi
                                           
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>

                        <!-- {{ data.item }} -->
                    </template>
                </b-table>
            </b-col>

            <b-col cols="12">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"
                    class="my-0" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BAlert,
        BCard
    } from 'bootstrap-vue'

    import Ripple from 'vue-ripple-directive'

    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
            BAlert,
            BCard
        },
        props: {
            items: {}
        },
        data() {
            return {
                perPage: 5000,
                pageOptions: [3, 5, 10],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],

                fields: [{
                    key: 'tagihan',
                    label: '',
                    thStyle: {
                        display: 'none'
                    }
                }, ],
            }
        },
        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length
        },
        emits:['loadDetail'],
        methods: {
            Rp(value) {
                let val = (value / 1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
            loadDetail(val){
                this.$emit('loadDetail', val)
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
        directives: {
            Ripple,
        },
    }
</script>